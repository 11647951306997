import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"   viewBox="0 0 1280.000000 1280.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">




<path d="M6695 8570 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M6745 8542 c6 -5 21 -13 35 -17 17 -5 22 -4 15 3 -5 5 -21 13 -35 17
-17 5 -22 4 -15 -3z"/>
<path d="M6715 8513 c6 -6 24 -14 40 -17 20 -5 27 -4 20 3 -5 5 -23 13 -40 17
-20 4 -27 3 -20 -3z"/>
<path d="M6820 8500 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M6810 8472 c0 -10 36 -9 46 1 4 4 -5 7 -19 7 -15 0 -27 -4 -27 -8z"/>
<path d="M6880 8455 c0 -8 -8 -15 -17 -15 -15 0 -14 -2 2 -14 21 -15 40 -11
29 6 -4 6 -1 14 6 18 8 5 7 9 -4 13 -10 4 -16 1 -16 -8z"/>
<path d="M6477 8446 c-4 -10 -1 -13 8 -9 8 3 12 9 9 14 -7 12 -11 11 -17 -5z"/>
<path d="M6520 8420 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M6918 8393 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M7010 8376 c0 -2 7 -9 15 -16 9 -7 15 -8 15 -2 0 5 -7 12 -15 16 -8
3 -15 4 -15 2z"/>
<path d="M6960 8361 c0 -5 7 -11 15 -15 8 -3 15 -1 15 3 0 5 -7 11 -15 15 -8
3 -15 1 -15 -3z"/>
<path d="M7065 8340 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M7065 8280 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M7145 8270 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M7102 8249 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
<path d="M7180 8241 c0 -13 29 -35 37 -27 3 3 1 6 -6 6 -8 0 -11 7 -7 15 3 9
0 15 -9 15 -8 0 -15 -4 -15 -9z"/>
<path d="M7140 8225 c0 -8 12 -22 28 -32 15 -10 32 -23 37 -28 6 -6 19 -18 29
-25 16 -12 18 -12 12 -1 -5 8 -3 17 5 23 10 7 8 8 -8 3 -14 -4 -26 0 -35 13
-7 10 -21 21 -30 25 -10 3 -18 13 -18 22 0 8 -4 15 -10 15 -5 0 -10 -7 -10
-15z"/>
<path d="M7281 8154 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5275 8100 c4 -6 57 -10 126 -10 78 0 118 4 114 10 -4 6 -57 10 -126
10 -78 0 -118 -4 -114 -10z"/>
<path d="M7280 8100 c0 -5 9 -10 21 -10 11 0 17 5 14 10 -3 6 -13 10 -21 10
-8 0 -14 -4 -14 -10z"/>
<path d="M7350 8087 c0 -19 16 -31 24 -18 3 5 -1 14 -9 21 -12 10 -15 10 -15
-3z"/>
<path d="M7312 8060 c13 -20 28 -27 28 -12 0 10 -22 32 -32 32 -5 0 -3 -9 4
-20z"/>
<path d="M4880 8042 c0 -5 14 -7 30 -4 17 2 30 6 30 8 0 2 -13 4 -30 4 -16 0
-30 -4 -30 -8z"/>
<path d="M4832 8019 c2 -7 10 -15 17 -17 8 -3 12 1 9 9 -2 7 -10 15 -17 17 -8
3 -12 -1 -9 -9z"/>
<path d="M5898 8023 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5956 8022 c-3 -6 -1 -14 5 -17 15 -10 25 3 12 16 -7 7 -13 7 -17 1z"/>
<path d="M5848 8013 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M7386 8002 c-9 -15 -1 -29 27 -43 21 -11 21 -11 6 7 -9 10 -18 26
-21 34 -3 10 -6 11 -12 2z"/>
<path d="M7426 7995 c4 -8 10 -12 15 -9 11 6 2 24 -11 24 -5 0 -7 -7 -4 -15z"/>
<path d="M6080 7980 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"/>
<path d="M4646 7954 c-19 -19 -21 -34 -3 -34 18 0 32 13 35 33 4 21 -11 22
-32 1z"/>
<path d="M6157 7953 c-3 -5 -2 -15 2 -22 7 -10 10 -10 16 -1 4 6 3 16 -3 22
-5 5 -12 6 -15 1z"/>
<path d="M4540 7933 l-35 -14 34 6 c18 3 40 5 47 5 8 0 14 5 14 10 0 13 -16
11 -60 -7z"/>
<path d="M7460 7936 c0 -24 18 -56 32 -56 7 0 6 5 -2 15 -6 8 -9 18 -6 23 3 6
-1 15 -9 22 -12 10 -15 9 -15 -4z"/>
<path d="M4465 7900 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M6240 7880 c0 -17 6 -20 38 -19 32 0 34 1 15 9 -13 5 -23 14 -23 20
0 5 -7 10 -15 10 -8 0 -15 -9 -15 -20z"/>
<path d="M4433 7868 c4 -25 27 -37 27 -14 0 9 -5 16 -11 16 -5 0 -7 5 -4 10 3
6 1 10 -4 10 -6 0 -9 -10 -8 -22z"/>
<path d="M4390 7861 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M6360 7861 c0 -5 9 -13 20 -16 11 -3 20 -11 20 -16 0 -5 5 -9 10 -9
19 0 10 16 -20 33 -16 10 -30 14 -30 8z"/>
<path d="M7510 7855 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z"/>
<path d="M7560 7857 c0 -9 -4 -17 -10 -17 -17 0 -11 -26 11 -46 21 -19 21 -19
8 9 -11 24 -11 30 1 37 12 7 12 12 2 22 -9 9 -12 9 -12 -5z"/>
<path d="M4358 7843 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5033 7833 c-20 -8 -15 -22 7 -20 11 1 20 7 20 14 0 14 -6 15 -27 6z"/>
<path d="M5441 7824 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5565 7830 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5600 7824 c0 -8 9 -14 20 -14 11 0 20 4 20 9 0 5 -9 11 -20 14 -13
3 -20 0 -20 -9z"/>
<path d="M5484 7815 c-9 -22 1 -29 15 -11 8 10 9 16 1 21 -5 3 -13 -1 -16 -10z"/>
<path d="M5680 7820 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M5808 7823 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5768 7813 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6445 7813 c11 -3 26 -10 34 -17 11 -9 13 -8 9 4 -2 8 -18 16 -34 17
-18 1 -22 0 -9 -4z"/>
<path d="M7596 7775 c4 -8 11 -15 16 -15 6 0 5 6 -2 15 -7 8 -14 15 -16 15 -2
0 -1 -7 2 -15z"/>
<path d="M4255 7770 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4290 7770 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4223 7756 c-10 -11 -9 -15 2 -20 10 -4 15 1 15 14 0 23 -1 24 -17 6z"/>
<path d="M5930 7752 c25 -15 70 -23 70 -12 0 5 -8 10 -17 10 -10 0 -29 3 -43
6 -18 4 -21 3 -10 -4z"/>
<path d="M4720 7740 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"/>
<path d="M7616 7727 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M6042 7718 c5 -15 28 -23 28 -10 0 5 -7 13 -16 16 -10 4 -14 1 -12
-6z"/>
<path d="M7681 7697 c-1 -32 1 -36 13 -25 8 7 10 15 6 18 -4 3 -10 14 -13 25
-3 12 -5 4 -6 -18z"/>
<path d="M6595 7700 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M4115 7667 c-6 -12 -18 -21 -27 -21 -10 1 -15 -3 -12 -8 4 -6 2 -13
-4 -17 -7 -4 -8 -12 -2 -21 8 -13 10 -13 10 1 0 9 11 20 24 23 13 3 26 16 29
28 9 31 -6 43 -18 15z"/>
<path d="M4540 7670 c0 -5 -12 -16 -27 -24 l-28 -15 34 6 c23 4 36 2 41 -8 13
-20 19 -8 13 24 -5 25 -33 40 -33 17z"/>
<path d="M5442 7628 c-2 -45 1 -58 13 -58 16 0 29 54 22 91 -2 15 -5 7 -6 -18
0 -24 -5 -43 -10 -43 -6 0 -12 19 -14 43 -3 36 -4 34 -5 -15z"/>
<path d="M6157 7669 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M6640 7660 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"/>
<path d="M7710 7662 c0 -12 19 -26 26 -19 2 2 -2 10 -11 17 -9 8 -15 8 -15 2z"/>
<path d="M6218 7623 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6641 7597 c0 -30 1 -31 10 -9 12 32 35 22 31 -13 -3 -25 -2 -27 11
-16 9 7 13 19 10 27 -3 9 1 14 13 14 27 0 -23 25 -53 27 -20 2 -23 -3 -22 -30z"/>
<path d="M4140 7610 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5267 7589 c4 -13 8 -18 11 -10 2 7 -1 18 -6 23 -8 8 -9 4 -5 -13z"/>
<path d="M6260 7596 c0 -9 30 -14 35 -6 4 6 -3 10 -14 10 -12 0 -21 -2 -21 -4z"/>
<path d="M6378 7593 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6725 7580 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M6355 7550 c3 -5 14 -10 23 -10 15 0 15 2 2 10 -20 13 -33 13 -25 0z"/>
<path d="M5237 7543 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z"/>
<path d="M5489 7545 c-3 -5 -6 -26 -7 -45 -2 -33 -1 -33 9 -10 10 24 9 74 -2
55z"/>
<path d="M7806 7515 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0
-7 -7 -4 -15z"/>
<path d="M4100 7509 c0 -8 12 -22 25 -32 26 -19 32 -13 15 13 -5 8 -10 11 -10
5 0 -5 -7 -1 -15 9 -11 14 -15 16 -15 5z"/>
<path d="M6420 7509 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M6465 7510 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M6560 7509 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5705 7490 c3 -5 13 -10 21 -10 8 0 12 5 9 10 -3 6 -13 10 -21 10 -8
0 -12 -4 -9 -10z"/>
<path d="M6602 7488 c-17 -17 -15 -33 3 -18 8 7 22 9 31 6 10 -4 14 -1 12 6
-6 18 -31 21 -46 6z"/>
<path d="M7770 7493 c0 -5 5 -15 10 -23 8 -12 10 -11 10 8 0 12 -4 22 -10 22
-5 0 -10 -3 -10 -7z"/>
<path d="M4923 7483 c-19 -7 -16 -43 3 -43 11 0 15 6 11 20 -3 11 -2 18 1 16
4 -2 16 0 27 5 18 7 18 8 -5 8 -14 -1 -31 -3 -37 -6z"/>
<path d="M5818 7483 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6471 7474 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7816 7464 c-7 -18 0 -34 15 -34 12 0 12 37 0 44 -5 3 -12 -1 -15
-10z"/>
<path d="M4480 7460 c0 -5 6 -10 14 -10 8 0 23 -3 33 -7 16 -6 16 -5 4 10 -15
18 -51 23 -51 7z"/>
<path d="M4197 7425 c-9 -21 11 -29 28 -11 20 19 19 26 -4 26 -10 0 -21 -7
-24 -15z"/>
<path d="M6510 7430 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M6000 7404 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M7844 7413 c10 -11 37 -12 30 -2 -3 5 -12 9 -21 9 -9 0 -13 -3 -9 -7z"/>
<path d="M4590 7394 c0 -17 18 -36 30 -32 7 2 4 10 -9 22 -12 11 -21 15 -21
10z"/>
<path d="M4785 7390 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M7820 7390 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4740 7376 c0 -9 7 -16 16 -16 9 0 14 5 12 12 -6 18 -28 21 -28 4z"/>
<path d="M6035 7380 c-3 -6 1 -13 10 -16 19 -8 30 0 20 15 -8 14 -22 14 -30 1z"/>
<path d="M4295 7351 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M4255 7340 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M6100 7336 c0 -14 29 -31 38 -21 6 6 -18 35 -29 35 -5 0 -9 -6 -9
-14z"/>
<path d="M7690 7321 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M4386 7272 c-3 -6 -15 -8 -26 -5 -25 7 -19 -17 10 -35 16 -10 18 -9
13 4 -3 9 0 20 8 24 7 5 11 12 7 15 -3 4 -9 2 -12 -3z"/>
<path d="M5210 7270 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M5708 7273 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6240 7255 c0 -8 2 -15 4 -15 2 0 11 -3 20 -6 23 -9 20 9 -4 24 -17
10 -20 10 -20 -3z"/>
<path d="M7916 7244 c-10 -26 5 -39 21 -19 7 11 13 22 13 27 0 15 -28 8 -34
-8z"/>
<path d="M5735 7230 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M5265 7220 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M7960 7218 c0 -7 5 -20 10 -28 8 -12 10 -11 10 7 0 12 -4 25 -10 28
-5 3 -10 0 -10 -7z"/>
<path d="M4408 7184 c24 -17 39 -14 27 5 -3 6 -16 11 -28 11 l-21 0 22 -16z"/>
<path d="M4460 7190 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4915 7180 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M6288 7173 c-29 -7 -36 -63 -7 -63 6 0 7 5 4 11 -4 5 1 19 11 30 19
21 17 27 -8 22z"/>
<path d="M7930 7171 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M4460 7151 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M4820 7150 c0 -5 6 -10 14 -10 8 0 18 5 21 10 3 6 -3 10 -14 10 -12
0 -21 -4 -21 -10z"/>
<path d="M5875 7110 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M6160 7109 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M7982 7100 c-16 -26 -15 -31 2 -24 9 4 16 -3 19 -18 2 -13 4 -4 3 19
-1 48 -6 52 -24 23z"/>
<path d="M4750 7100 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M4515 7090 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M6182 7069 c-1 -35 9 -37 24 -5 10 20 9 25 -6 29 -13 3 -17 -2 -18
-24z"/>
<path d="M6241 7084 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8027 7060 c3 -11 9 -20 15 -20 5 0 4 9 -2 20 -6 11 -12 20 -14 20
-2 0 -2 -9 1 -20z"/>
<path d="M4630 7031 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M6080 7029 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M4580 7021 c0 -11 26 -22 34 -14 3 3 3 10 0 14 -7 12 -34 11 -34 0z"/>
<path d="M5460 7009 c0 -5 -4 -7 -10 -4 -5 3 -10 -1 -10 -10 0 -10 5 -13 12
-9 6 4 17 -1 24 -10 19 -26 36 -11 22 19 -11 25 -38 35 -38 14z"/>
<path d="M6117 7008 c-17 -9 -24 -58 -9 -58 4 0 13 13 20 29 8 18 19 28 27 25
7 -3 16 -1 19 4 7 12 -36 12 -57 0z"/>
<path d="M8040 7006 c0 -21 11 -46 20 -46 10 0 0 49 -11 56 -5 3 -9 -1 -9 -10z"/>
<path d="M5825 7001 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M5196 6974 c-4 -10 -1 -14 6 -12 15 5 23 28 10 28 -5 0 -13 -7 -16
-16z"/>
<path d="M5240 6975 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M5545 6970 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M5795 6970 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M6000 6949 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M8026 6935 c2 -11 4 -26 4 -33 0 -10 4 -10 20 0 25 16 26 28 1 28
-11 0 -21 6 -24 13 -2 6 -3 3 -1 -8z"/>
<path d="M6036 6924 c-17 -16 -22 -44 -8 -44 4 0 13 9 20 20 7 11 19 20 27 20
8 0 15 5 15 10 0 16 -37 12 -54 -6z"/>
<path d="M5096 6921 c-3 -5 1 -11 9 -15 9 -3 15 0 15 9 0 16 -16 20 -24 6z"/>
<path d="M4995 6900 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5978 6873 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4874 6839 c-8 -13 25 -24 37 -12 7 7 3 12 -10 16 -12 3 -24 1 -27
-4z"/>
<path d="M5921 6814 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4827 6805 c-3 -7 3 -24 13 -37 11 -13 19 -18 20 -11 0 6 -7 19 -16
27 -8 9 -11 16 -5 16 6 0 11 5 11 10 0 15 -17 12 -23 -5z"/>
<path d="M8060 6809 c0 -5 10 -9 22 -9 12 0 18 3 14 7 -10 11 -36 12 -36 2z"/>
<path d="M5840 6789 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M8090 6763 c0 -17 5 -35 10 -38 13 -8 13 35 0 55 -7 11 -10 7 -10
-17z"/>
<path d="M5879 6761 c-13 -11 -19 -20 -14 -20 14 0 50 28 43 34 -3 3 -16 -3
-29 -14z"/>
<path d="M4912 6700 c7 -11 16 -20 21 -20 4 0 3 9 -3 20 -6 11 -15 20 -20 20
-6 0 -5 -9 2 -20z"/>
<path d="M5120 6710 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5516 6711 c-3 -5 1 -11 9 -15 8 -3 15 -1 15 4 0 13 -18 22 -24 11z"/>
<path d="M5640 6700 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"/>
<path d="M5795 6680 c-10 -11 -15 -26 -11 -37 6 -14 9 -13 21 10 8 15 17 31
20 37 11 18 -11 11 -30 -10z"/>
<path d="M5585 6680 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M7942 6665 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M4980 6630 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M5017 6633 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M8111 6624 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8120 6549 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M8170 6490 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5465 6450 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M5201 6436 c-10 -12 -8 -13 9 -9 25 7 37 23 17 23 -8 0 -20 -6 -26
-14z"/>
<path d="M5260 6436 c0 -8 5 -18 10 -21 6 -3 10 3 10 14 0 12 -4 21 -10 21 -5
0 -10 -6 -10 -14z"/>
<path d="M5555 6420 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M8187 6411 c-4 -17 -3 -21 5 -13 5 5 8 16 6 23 -3 8 -7 3 -11 -10z"/>
<path d="M5505 6410 c-3 -5 3 -10 15 -10 12 0 18 5 15 10 -3 6 -10 10 -15 10
-5 0 -12 -4 -15 -10z"/>
<path d="M5325 6290 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M5440 6279 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M8171 6244 c-18 -21 -6 -30 14 -11 8 9 15 18 15 21 0 11 -15 5 -29
-10z"/>
<path d="M3760 5405 l0 -625 173 2 172 3 5 345 5 345 34 -55 c19 -30 46 -68
59 -84 13 -17 28 -39 32 -50 5 -12 25 -44 47 -73 21 -29 52 -75 68 -104 17
-28 40 -63 51 -77 l22 -26 78 118 c44 65 93 140 109 166 17 27 53 81 80 122
l50 73 5 -160 5 -160 170 0 170 0 0 430 0 430 -179 3 -180 2 -23 -27 c-13 -16
-37 -53 -55 -83 -68 -119 -103 -176 -127 -205 -13 -16 -32 -48 -42 -70 -10
-22 -27 -51 -39 -65 l-20 -25 -21 25 c-12 14 -34 51 -50 82 -16 31 -33 59 -38
62 -4 3 -28 38 -51 78 -24 40 -65 108 -93 151 l-50 77 -183 0 -184 0 0 -625z"/>
<path d="M5176 6004 c-9 -35 -6 -263 4 -269 4 -2 104 -5 224 -6 121 0 216 -5
216 -10 0 -5 -6 -9 -14 -9 -30 0 -35 -29 -40 -257 -5 -202 -4 -233 9 -241 14
-7 14 -14 2 -63 l-14 -54 -404 -5 -404 -5 0 -150 0 -150 398 -3 397 -2 2 -53
c6 -205 -19 -283 -114 -343 -42 -27 -89 -30 -150 -12 -34 10 -39 15 -33 35 3
13 1 23 -5 23 -5 0 -10 -7 -10 -15 0 -23 -14 -18 -37 13 -32 43 -80 38 -168
-17 -38 -24 -80 -53 -92 -65 -12 -11 -29 -27 -38 -34 -11 -9 -15 -31 -15 -77
0 -54 4 -68 26 -94 14 -17 23 -35 19 -41 -4 -6 5 -10 19 -10 15 0 26 -6 26
-14 0 -7 14 -19 31 -27 18 -7 44 -23 60 -36 16 -14 33 -21 39 -18 6 4 16 2 23
-3 7 -6 74 -18 150 -27 119 -13 144 -14 185 -2 26 8 62 12 80 10 17 -3 32 -1
32 5 0 5 17 12 38 16 21 3 47 15 57 26 10 11 25 20 34 20 9 0 35 28 59 61 26
36 56 66 72 72 25 10 33 23 56 92 34 103 55 199 44 203 -4 2 -5 16 0 32 4 16
4 46 0 66 -5 21 -6 50 -3 64 3 15 6 56 6 91 l2 63 105 31 c132 38 196 74 285
161 73 71 113 139 152 254 28 86 25 293 -6 380 -52 141 -124 233 -243 310 -61
40 -86 51 -163 70 -152 38 -168 39 -531 40 l-362 0 -6 -26z m534 -284 c0 -5
-4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m73 3 c-7
-2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m72 -3 c4 -6 -5 -10 -19
-10 -14 0 -26 5 -26 10 0 6 9 10 19 10 11 0 23 -4 26 -10z m136 -34 c47 -20
114 -92 140 -151 32 -71 32 -191 1 -260 -41 -89 -155 -186 -185 -156 -8 8 -7
15 4 27 12 12 14 25 8 57 -4 23 -4 47 1 52 5 6 4 33 -1 60 -5 28 -9 68 -8 90
1 22 -1 47 -5 55 -8 21 -3 140 7 140 4 0 5 23 1 50 -4 28 -4 50 -2 50 3 0 21
-6 39 -14z m-301 -216 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10
8 0 15 -4 15 -10z m70 -354 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11
10 11 6 0 10 -2 10 -4z m-50 -16 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8
10 11 10 2 0 4 -4 4 -10z"/>
<path d="M8166 5925 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
<path d="M2450 5903 c0 -13 5 -23 10 -23 13 0 13 11 0 30 -8 12 -10 11 -10 -7z"/>
<path d="M8187 5829 c4 -13 8 -18 11 -10 2 7 -1 18 -6 23 -8 8 -9 4 -5 -13z"/>
<path d="M8150 5764 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M8142 5660 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M8160 5655 c1 -26 2 -28 11 -12 8 14 8 22 0 30 -8 8 -11 3 -11 -18z"/>
<path d="M8107 5466 c-4 -10 -5 -21 -2 -24 9 -9 17 6 13 25 -3 17 -4 17 -11
-1z"/>
<path d="M8135 5430 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M2555 5350 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M8089 5338 c0 -7 -2 -29 -3 -48 l-3 -35 13 30 c7 17 13 38 14 48 0
19 -20 24 -21 5z"/>
<path d="M8067 5293 c-4 -3 -7 -13 -7 -22 1 -13 3 -13 11 2 11 19 8 33 -4 20z"/>
<path d="M2568 5264 c2 -13 8 -24 13 -24 14 0 10 37 -4 43 -10 4 -13 -2 -9
-19z"/>
<path d="M2592 5163 c4 -20 22 -21 26 -2 2 9 -4 16 -13 16 -9 0 -15 -7 -13
-14z"/>
<path d="M8026 5157 c-9 -33 -7 -44 5 -24 6 9 14 15 20 12 5 -4 9 0 9 8 0 25
-27 28 -34 4z"/>
<path d="M8003 5075 c-9 -24 1 -27 15 -5 8 13 8 20 2 20 -6 0 -13 -7 -17 -15z"/>
<path d="M7990 5030 c0 -5 -9 -19 -20 -30 -11 -11 -20 -26 -20 -32 1 -7 9 -3
19 9 10 12 20 20 23 18 2 -2 5 7 6 20 1 14 0 25 -3 25 -3 0 -5 -4 -5 -10z"/>
<path d="M2657 5006 c-6 -15 1 -26 15 -26 11 0 10 27 -1 34 -5 3 -11 0 -14 -8z"/>
<path d="M7996 4967 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M7970 4939 c0 -11 5 -17 10 -14 6 3 10 13 10 21 0 8 -4 14 -10 14 -5
0 -10 -9 -10 -21z"/>
<path d="M2695 4910 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M7915 4878 c4 -11 2 -18 -4 -18 -6 0 -11 -4 -11 -10 0 -16 17 -11 24
7 4 9 2 21 -4 27 -8 8 -9 6 -5 -6z"/>
<path d="M7961 4874 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2720 4860 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M7911 4814 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2740 4790 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2772 4751 c2 -7 7 -12 11 -12 12 1 9 15 -3 20 -7 2 -11 -2 -8 -8z"/>
<path d="M2800 4720 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2857 4639 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M2840 4602 c0 -5 7 -16 16 -23 14 -12 16 -11 13 5 -4 20 -29 35 -29
18z"/>
<path d="M7741 4537 c-6 -16 -11 -34 -10 -40 0 -7 5 -1 10 13 8 20 12 22 19
10 9 -13 10 -13 10 0 0 8 -4 22 -10 30 -7 11 -11 9 -19 -13z"/>
<path d="M7770 4550 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M7696 4485 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z"/>
<path d="M2996 4428 c4 -17 2 -28 -5 -28 -14 0 -2 -33 16 -43 8 -5 18 -18 23
-30 l9 -22 0 22 c1 13 -8 27 -19 33 -21 11 -28 40 -10 40 14 0 12 13 -5 36
-15 18 -15 18 -9 -8z"/>
<path d="M7634 4409 c-9 -16 14 -28 40 -20 19 5 18 6 -7 9 -15 2 -25 8 -22 13
4 5 4 9 1 9 -3 0 -8 -5 -12 -11z"/>
<path d="M7615 4370 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3045 4360 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M7565 4281 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z"/>
<path d="M3130 4241 c-8 -6 -17 -8 -21 -5 -4 2 -5 -5 -2 -16 5 -19 6 -20 19
-3 13 17 15 17 29 -1 15 -20 20 -10 9 18 -7 18 -13 19 -34 7z"/>
<path d="M7535 4240 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M7490 4221 c0 -6 6 -11 14 -11 8 0 17 -6 20 -12 3 -7 5 -4 3 7 -2 20
-37 35 -37 16z"/>
<path d="M7450 4169 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3200 4161 c-18 -5 -21 -9 -11 -15 8 -5 19 -2 29 8 9 9 14 16 12 15
-3 -1 -16 -4 -30 -8z"/>
<path d="M7420 4145 c0 -3 7 -14 16 -23 15 -14 16 -14 13 3 -4 17 -29 35 -29
20z"/>
<path d="M3270 4051 c0 -9 30 -24 36 -18 2 1 -6 8 -16 15 -11 7 -20 8 -20 3z"/>
<path d="M3352 4010 c-7 -11 -8 -20 -3 -20 9 0 24 30 18 36 -1 2 -8 -6 -15
-16z"/>
<path d="M7290 4010 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M7267 3995 c-4 -8 -2 -17 3 -20 6 -4 10 3 10 14 0 25 -6 27 -13 6z"/>
<path d="M3392 3979 c2 -7 10 -15 17 -17 8 -3 12 1 9 9 -2 7 -10 15 -17 17 -8
3 -12 -1 -9 -9z"/>
<path d="M3437 3953 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M7225 3940 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3440 3906 c0 -2 11 -6 25 -8 13 -3 22 -1 19 3 -5 9 -44 13 -44 5z"/>
<path d="M2740 3756 c0 -68 -3 -133 -6 -143 -6 -14 -4 -15 6 -6 8 8 12 48 11
138 l-2 128 41 -7 c27 -4 39 -3 35 4 -3 5 -24 10 -46 10 l-39 0 0 -124z"/>
<path d="M2875 3870 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M2960 3859 c0 -5 6 -9 13 -9 6 0 23 -3 37 -7 36 -9 19 6 -19 17 -19
5 -31 5 -31 -1z"/>
<path d="M3513 3860 c2 -8 12 -16 23 -17 13 -2 15 -1 4 4 -8 3 -19 11 -23 17
-6 7 -7 6 -4 -4z"/>
<path d="M2600 3856 c0 -2 11 -6 25 -8 13 -3 22 -1 19 3 -5 9 -44 13 -44 5z"/>
<path d="M2548 3843 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2778 3843 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2888 3843 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3120 3816 c0 -2 15 -9 33 -15 29 -10 30 -9 13 4 -18 14 -46 20 -46
11z"/>
<path d="M3558 3813 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2370 3780 c-19 -11 -29 -19 -22 -20 7 0 24 7 37 16 24 16 51 11 40
-7 -4 -5 -1 -9 4 -9 17 0 13 27 -5 34 -19 7 -12 9 -54 -14z"/>
<path d="M3120 3780 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3200 3779 c0 -9 48 -24 55 -17 2 2 -9 9 -25 15 -17 6 -30 7 -30 2z"/>
<path d="M3596 3777 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M2470 3760 c8 -5 20 -10 25 -10 6 0 3 5 -5 10 -8 5 -19 10 -25 10 -5
0 -3 -5 5 -10z"/>
<path d="M2606 3745 c4 -8 10 -15 15 -15 4 0 6 7 3 15 -4 8 -10 15 -15 15 -4
0 -6 -7 -3 -15z"/>
<path d="M2771 3712 c-1 -40 4 -58 19 -74 l20 -22 0 35 c0 24 -3 30 -9 20 -10
-16 -16 1 -24 64 -2 17 -5 7 -6 -23z"/>
<path d="M2837 3749 c-9 -5 -22 -7 -27 -4 -6 3 -10 1 -10 -4 0 -13 47 -15 55
-2 3 5 22 7 43 4 20 -3 35 -4 32 -1 -11 12 -76 17 -93 7z"/>
<path d="M3020 3748 c23 -9 24 -11 6 -17 -17 -6 -17 -8 -4 -14 9 -3 18 0 21 6
7 21 -4 37 -26 36 -21 0 -20 -1 3 -11z"/>
<path d="M3083 3753 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M2276 3731 c-3 -5 5 -8 19 -7 14 0 25 4 25 9 0 10 -38 9 -44 -2z"/>
<path d="M2525 3732 c-14 -10 -17 -32 -5 -32 5 0 14 9 20 20 11 20 4 26 -15
12z"/>
<path d="M3100 3730 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3715 3730 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M2560 3719 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3320 3711 c0 -5 -9 -7 -20 -4 -11 3 -20 1 -20 -5 0 -15 32 -24 40
-11 6 8 13 8 29 0 29 -16 27 -7 -4 13 -14 9 -25 12 -25 7z"/>
<path d="M3080 3700 c8 -5 22 -10 30 -10 13 0 13 1 0 10 -8 5 -22 10 -30 10
-13 0 -13 -1 0 -10z"/>
<path d="M2813 3693 c16 -4 18 -16 15 -134 -2 -71 0 -129 5 -129 4 0 7 61 7
135 0 134 0 135 -22 134 -18 -1 -19 -2 -5 -6z"/>
<path d="M2330 3670 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2414 3665 c-17 -13 -16 -14 14 -4 33 11 41 19 19 19 -7 0 -22 -7
-33 -15z"/>
<path d="M2180 3655 c-11 -13 -10 -19 5 -33 14 -13 16 -13 7 -1 -9 12 -9 18 0
27 6 6 9 14 6 17 -3 3 -11 -2 -18 -10z"/>
<path d="M3265 3660 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3780 3660 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M3367 3653 c-12 -12 -7 -22 8 -17 8 4 15 10 15 15 0 11 -14 12 -23 2z"/>
<path d="M2360 3639 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3220 3640 c0 -9 32 -30 46 -30 4 0 -1 9 -11 20 -19 21 -35 26 -35
10z"/>
<path d="M3400 3636 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M2320 3619 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3880 3620 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2760 3610 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3356 3584 c-14 -16 -23 -30 -20 -33 2 -3 16 7 30 23 14 15 24 30 21
32 -3 3 -17 -7 -31 -22z"/>
<path d="M2278 3593 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2316 3582 c-6 -18 -3 -28 13 -43 25 -24 40 -25 18 -1 -8 9 -17 28
-19 42 l-4 25 -8 -23z"/>
<path d="M3335 3590 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3916 3585 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z"/>
<path d="M6710 3585 c-7 -9 -8 -15 -2 -15 5 0 12 7 16 15 3 8 4 15 2 15 -2 0
-9 -7 -16 -15z"/>
<path d="M2220 3581 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M2360 3581 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M2590 3580 c-13 -8 -13 -10 2 -10 9 0 20 5 23 10 8 13 -5 13 -25 0z"/>
<path d="M3295 3580 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M2998 3573 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2941 3554 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3040 3561 c0 -5 -10 -11 -22 -13 -13 -2 -18 -6 -13 -10 6 -3 21 -1
34 6 15 9 20 16 12 20 -6 4 -11 3 -11 -3z"/>
<path d="M2250 3556 c0 -2 7 -9 15 -16 12 -10 15 -10 15 4 0 9 -7 16 -15 16
-8 0 -15 -2 -15 -4z"/>
<path d="M2618 3553 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2552 3541 c-10 -6 -10 -10 2 -20 10 -9 23 -9 48 -2 l33 11 -37 -6
c-38 -5 -54 10 -20 19 12 3 13 5 2 6 -8 0 -21 -3 -28 -8z"/>
<path d="M3070 3540 c0 -5 9 -10 21 -10 11 0 17 5 14 10 -3 6 -13 10 -21 10
-8 0 -14 -4 -14 -10z"/>
<path d="M2478 3528 c5 -5 16 -8 23 -6 8 3 3 7 -10 11 -17 4 -21 3 -13 -5z"/>
<path d="M2937 3523 c-7 -11 10 -70 18 -62 2 2 0 20 -4 39 -4 20 -10 30 -14
23z"/>
<path d="M2392 3500 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2748 3513 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4030 3510 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2710 3503 c0 -6 7 -16 15 -23 8 -7 15 -29 16 -49 l1 -36 10 34 c8
30 6 37 -16 60 -14 14 -26 21 -26 14z"/>
<path d="M2336 3491 c-6 -10 22 -37 29 -29 8 7 -5 38 -15 38 -5 0 -11 -4 -14
-9z"/>
<path d="M2781 3484 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2996 3492 c-6 -5 24 -31 37 -32 4 0 7 6 7 14 0 14 -34 28 -44 18z"/>
<path d="M2593 3483 c-14 -5 -18 -33 -5 -33 4 0 13 5 20 12 22 22 14 33 -15
21z"/>
<path d="M2640 3471 c0 -4 11 -6 25 -3 14 2 25 6 25 8 0 2 -11 4 -25 4 -14 0
-25 -4 -25 -9z"/>
<path d="M3189 3459 c-14 -19 -45 -27 -34 -9 3 6 -1 7 -10 4 -9 -4 -14 -12
-11 -20 3 -7 0 -14 -7 -17 -9 -3 -9 -9 1 -28 12 -22 12 -23 8 -2 -4 24 17 49
33 39 5 -4 17 4 26 16 17 23 17 23 11 -12 -3 -19 -3 -28 1 -20 3 8 12 20 18
25 9 8 8 14 -5 26 -16 15 -18 15 -31 -2z"/>
<path d="M4190 3460 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2351 3438 c2 -2 21 -19 42 -37 28 -27 37 -31 37 -18 0 9 -6 17 -14
17 -8 0 -18 9 -21 20 -4 12 -15 20 -27 20 -12 0 -19 -1 -17 -2z"/>
<path d="M2470 3429 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M6360 3425 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
<path d="M2496 3415 c-11 -8 -18 -17 -15 -19 2 -3 15 4 29 14 14 11 21 20 15
19 -5 0 -19 -6 -29 -14z"/>
<path d="M3017 3405 c-9 -14 -13 -26 -8 -30 6 -3 15 4 21 15 17 31 8 42 -13
15z"/>
<path d="M4250 3420 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"/>
<path d="M2766 3393 c10 -40 14 -41 14 -5 0 18 -5 32 -10 32 -6 0 -8 -11 -4
-27z"/>
<path d="M2800 3394 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M3166 3394 c-4 -10 -1 -14 6 -12 15 5 23 28 10 28 -5 0 -13 -7 -16
-16z"/>
<path d="M2450 3390 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4397 3366 c-4 -10 -1 -13 8 -9 8 3 12 9 9 14 -7 12 -11 11 -17 -5z"/>
<path d="M3050 3345 c-7 -8 -8 -15 -2 -15 5 0 15 7 22 15 7 8 8 15 2 15 -5 0
-15 -7 -22 -15z"/>
<path d="M3090 3334 c0 -23 -3 -26 -25 -21 -16 3 -24 0 -22 -7 6 -18 33 -18
51 -1 18 18 22 55 6 55 -5 0 -10 -12 -10 -26z"/>
<path d="M3125 3349 c-11 -16 -1 -19 13 -3 7 8 8 14 3 14 -5 0 -13 -5 -16 -11z"/>
<path d="M2665 3340 l-20 -9 20 0 c11 0 29 4 40 9 l20 9 -20 0 c-11 0 -29 -4
-40 -9z"/>
<path d="M2880 3340 c0 -7 24 -20 36 -20 13 0 1 19 -16 23 -11 3 -20 2 -20 -3z"/>
<path d="M6120 3334 c0 -17 22 -14 28 4 2 7 -3 12 -12 12 -9 0 -16 -7 -16 -16z"/>
<path d="M2627 3323 c-4 -6 -1 -14 5 -16 15 -6 -8 -27 -31 -27 -11 0 -11 3 -1
15 9 11 9 15 1 15 -6 0 -14 -7 -18 -16 -3 -8 -11 -13 -19 -10 -7 3 -17 -1 -23
-8 -8 -10 -7 -17 5 -27 15 -12 16 -11 9 10 -5 16 -4 21 4 17 6 -4 36 -4 65 -1
41 5 53 11 49 21 -3 8 -11 14 -18 14 -7 0 -15 5 -17 12 -3 9 -6 9 -11 1z"/>
<path d="M2700 3310 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2880 3300 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"/>
<path d="M4690 3295 c0 -8 8 -11 20 -8 30 8 33 21 5 21 -14 0 -25 -6 -25 -13z"/>
<path d="M5920 3299 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2718 3278 c6 -6 18 -8 28 -6 14 3 12 5 -9 10 -19 3 -25 2 -19 -4z"/>
<path d="M3045 3269 c-11 -16 -1 -19 13 -3 7 8 8 14 3 14 -5 0 -13 -5 -16 -11z"/>
<path d="M4777 3273 c-15 -2 -25 -9 -22 -14 9 -13 37 -10 59 6 21 16 18 16
-37 8z"/>
<path d="M5848 3273 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5928 3273 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2637 3263 c-13 -3 -16 -11 -11 -31 9 -38 21 -48 16 -14 -3 25 0 30
30 39 28 9 29 11 8 11 -14 0 -33 -2 -43 -5z"/>
<path d="M2965 3260 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M3010 3255 c0 -8 -10 -20 -22 -24 -21 -8 -21 -10 -5 -16 30 -13 64
33 37 50 -5 3 -10 -1 -10 -10z"/>
<path d="M5680 3260 c0 -5 15 -10 33 -10 17 0 43 -2 57 -5 14 -3 7 2 -15 9
-48 17 -75 19 -75 6z"/>
<path d="M4888 3253 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5048 3253 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2693 3236 c-13 -6 -23 -14 -23 -19 0 -4 9 -3 19 3 11 5 28 7 38 3
16 -6 16 -5 4 10 -7 9 -14 16 -15 16 0 -1 -11 -6 -23 -13z"/>
<path d="M2580 3231 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M4920 3228 c0 -4 9 -8 20 -8 11 0 20 4 20 9 0 6 -9 9 -20 8 -11 -1
-20 -5 -20 -9z"/>
<path d="M5600 3230 c0 -5 12 -10 28 -9 24 0 25 1 7 9 -27 12 -35 12 -35 0z"/>
<path d="M2848 3223 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5108 3220 c26 -11 102 -9 102 3 0 4 -28 7 -62 6 -49 0 -58 -2 -40
-9z"/>
<path d="M5360 3220 c0 -6 16 -8 43 -4 58 9 59 14 4 14 -26 0 -47 -5 -47 -10z"/>
<path d="M2720 3100 c-8 -5 -12 -11 -9 -14 3 -3 14 1 25 9 21 16 8 20 -16 5z"/>
<path d="M2770 3085 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M2840 3096 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
-15 -2 -15 -4z"/>
<path d="M2807 3084 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z"/>
<path d="M2740 3060 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2840 3059 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2272 2993 c2 -52 7 -68 18 -69 8 -1 21 -2 28 -2 10 -2 12 -55 10
-244 l-3 -243 -25 0 c-24 0 -25 -3 -25 -68 0 -37 3 -67 8 -67 4 0 70 0 147 0
l140 0 0 64 0 65 -32 3 -33 3 -1 215 c-2 205 -1 212 12 155 18 -77 135 -499
140 -504 4 -4 226 -2 230 2 1 1 32 115 68 252 37 138 70 264 75 280 6 20 9
-42 10 -182 l1 -212 -27 -3 c-28 -3 -28 -4 -28 -72 l0 -69 155 1 c85 1 161 2
168 2 8 0 12 21 12 70 l0 70 -30 0 -30 0 2 243 3 242 23 1 c12 1 25 2 30 3 4
0 5 30 4 66 l-4 65 -207 0 -206 0 -34 -127 c-18 -71 -41 -158 -49 -195 -9 -38
-19 -68 -23 -68 -3 0 -27 80 -53 178 -26 97 -50 185 -52 195 -5 15 -24 17
-215 17 l-210 0 3 -67z"/>
<path d="M4032 2993 l3 -68 33 -3 32 -3 0 -239 0 -239 -32 -3 -33 -3 0 -69 0
-69 215 2 c224 2 275 9 350 46 168 85 238 327 148 513 -37 76 -89 124 -177
166 l-66 31 -238 3 -238 3 3 -68z m394 -83 c36 -14 70 -55 90 -107 24 -62 16
-213 -14 -274 -26 -53 -81 -89 -136 -89 l-36 0 0 240 0 240 34 0 c19 0 47 -5
62 -10z"/>
<path d="M6590 2990 l0 -70 51 0 51 0 -4 -312 c-3 -318 -6 -337 -43 -375 -7
-6 -32 -13 -56 -15 l-43 -3 3 -65 c1 -35 5 -69 8 -73 3 -5 41 -7 83 -5 92 5
177 41 214 91 52 69 56 102 56 442 l0 315 45 0 45 0 0 70 0 70 -205 0 -205 0
0 -70z"/>
<path d="M2796 3039 c4 -8 -1 -9 -17 -4 -13 4 -22 6 -19 4 3 -2 15 -12 26 -22
21 -17 22 -17 27 2 3 12 -1 22 -10 25 -8 4 -11 2 -7 -5z"/>
<path d="M5633 2978 l-93 -31 0 -52 c0 -51 -1 -52 -27 -51 -28 1 -28 1 -28
-69 0 -68 1 -70 27 -73 l26 -3 4 -162 c5 -230 24 -258 175 -256 126 2 128 3
128 79 l0 65 -44 -3 c-29 -3 -46 1 -52 10 -5 7 -9 70 -9 140 l0 127 53 3 52 3
3 49 c2 27 1 60 -3 74 -5 21 -10 23 -53 20 l-47 -3 -3 83 c-2 45 -6 82 -10 81
-4 -1 -49 -15 -99 -31z"/>
<path d="M5010 2869 c-62 -18 -139 -51 -150 -64 -9 -10 -7 -28 6 -69 10 -31
20 -56 23 -56 3 0 30 9 59 20 71 27 165 27 192 0 11 -11 20 -31 20 -45 0 -30
2 -29 -46 -9 -74 31 -198 -1 -248 -63 -51 -65 -61 -157 -21 -215 65 -96 220
-122 311 -53 31 24 32 24 45 5 16 -21 35 -24 162 -22 l87 2 0 64 0 65 -32 3
-33 3 -5 147 c-5 136 -7 149 -30 180 -32 44 -65 69 -116 90 -53 21 -177 31
-224 17z m128 -355 c46 -32 15 -94 -48 -94 -43 0 -60 14 -60 51 0 31 31 59 65
59 11 0 31 -7 43 -16z"/>
<path d="M6072 2869 c-57 -11 -162 -59 -162 -74 0 -11 30 -106 36 -113 1 -2
32 6 68 18 116 40 206 19 206 -47 0 -27 2 -27 -43 -8 -97 40 -234 -12 -278
-105 -16 -33 -20 -57 -17 -96 8 -100 91 -166 209 -167 48 0 67 5 107 30 47 29
48 29 63 10 13 -17 27 -19 129 -19 l115 1 3 65 3 65 -33 3 -33 3 -5 140 c-4
123 -8 145 -28 180 -33 56 -89 93 -169 110 -75 16 -107 17 -171 4z m126 -355
c46 -32 15 -94 -48 -94 -43 0 -60 14 -60 51 0 31 31 59 65 59 11 0 31 -7 43
-16z"/>
<path d="M7255 2847 c-132 -44 -204 -131 -212 -257 -10 -135 62 -244 190 -289
155 -54 331 9 390 139 30 67 30 189 -2 253 -29 60 -89 116 -146 139 -62 24
-170 31 -220 15z m132 -149 c22 -18 35 -80 31 -153 -3 -61 -7 -78 -28 -100
-52 -55 -111 -8 -118 95 -6 80 4 127 34 157 24 24 51 25 81 1z"/>
<path d="M3368 2843 c-15 -4 -18 -16 -18 -69 0 -56 2 -64 19 -64 26 0 26 -1
140 -218 100 -189 102 -195 90 -230 -22 -71 -60 -89 -108 -55 -23 16 -25 15
-48 -13 -13 -16 -32 -43 -42 -61 -18 -29 -18 -32 -3 -46 34 -29 66 -39 129
-39 78 1 140 28 179 79 15 20 78 159 139 307 105 255 113 271 139 274 27 3 27
4 24 70 l-3 67 -125 1 c-69 0 -131 -1 -137 -4 -9 -3 -13 -26 -13 -68 0 -62 1
-64 25 -64 14 0 25 -3 25 -7 0 -13 -64 -163 -69 -163 -10 1 -70 153 -65 162 3
4 14 8 25 8 18 0 20 6 17 68 l-3 67 -150 1 c-82 0 -158 -1 -167 -3z"/>
<path d="M7655 2842 c-3 -3 -5 -34 -5 -69 0 -55 2 -63 19 -63 21 0 38 -27 162
-264 l80 -155 -20 -45 c-23 -54 -58 -68 -101 -42 -16 9 -30 16 -33 16 -5 0
-77 -118 -77 -126 0 -2 18 -13 40 -24 53 -27 156 -28 208 -1 73 38 98 75 166
246 19 50 60 151 92 225 31 74 58 143 61 153 4 11 15 17 35 17 l29 0 -3 68 -3
67 -135 0 -135 0 -3 -67 c-3 -61 -1 -68 16 -68 10 0 21 -3 25 -6 5 -6 -48
-142 -63 -157 -4 -5 -16 13 -26 40 -10 26 -24 59 -31 73 -19 37 -16 50 12 50
26 0 26 1 23 68 l-3 67 -162 1 c-90 0 -165 -1 -168 -4z"/>
</g>
</svg>

		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
